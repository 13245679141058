
import {computed, defineComponent, reactive, toRaw, toRefs, watch,ref,nextTick,onMounted,onUpdated} from 'vue';
import avator from '@/components/tools/avator.vue';
import contributorDetail from "@/components/page/contributorDetail.vue";
import {useRoute,useRouter} from 'vue-router';
import axios from '@/api/http.js';
import {ElMessage, ElMessageBox} from "element-plus";
import {ElLoading} from 'element-plus';
import {Countdown} from 'vue3-flip-countdown'
import moment from 'moment'
import elq from '@/utils/arubaElq.js';
import 'vue3-video-play/dist/style.css'
import { videoPlay } from 'vue-video-play';


interface DataProps {
}

export default defineComponent({
  name: '',
  components: {
    contributorDetail,
    avator,
    Countdown,
    videoPlay,
  },
  props: {
    data: {
      type: Object,
    },
  },

  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const myVideo = ref(null);
    // const detail = toRaw(props.data);
    // console.log("==== detail=====",detail)
    // console.log("==== assignedAttendees=====",detail['assignedAttendees'])
    // detail: JSON.parse(JSON.stringify(props.data))
    const client = localStorage.getItem("eventClient");

    // action图标 （根据client去获取对应文件夹下的图片，所以名字要一致）
    const imgSurvey = require(`../../assets/imgs/${client}/actionIcon/survey-icon.png`)
    const imgSurveyActive = require(`../../assets/imgs/${client}/actionIcon/survey-icon-active.png`)
    const imgAgenda = require(`../../assets/imgs/agendaIcon/add-to-agenda-icon.png`)
    const imgAgendaActive = require(`../../assets/imgs/agendaIcon/remove-from-agenda-icon.png`)
    const imgResources = require(`../../assets/imgs/${client}/actionIcon/resources-icon.png`)
    const imgResourcesActive = require(`../../assets/imgs/${client}/actionIcon/resources-icon-active.png`)
    const imgCalendar = require(`../../assets/imgs/${client}/actionIcon/calendar-icon.png`)
    const imgCalendarActive = require(`../../assets/imgs/${client}/actionIcon/calendar-icon-active.png`)
    const imgConversation = require(`../../assets/imgs/${client}/actionIcon/conversation-icon.png`)
    const imgConversationActive = require(`../../assets/imgs/${client}/actionIcon/conversation-icon-active.png`)
    const isFull = require(`../../assets/imgs/agendaIcon/remove-from-agenda-icon.png`)

    const data = reactive({
      showPerson:true,//显示下方人物
      contentTypeIsppt:false,//content type是ppt才显示preview按钮
      dialogVisible: false,
      dialogPreview: false,
      dialogSurvey: false,
      dialogResource: false,
      // rateValue: null,
      originRateValue: null,
      showAddToFavorite: true,
      id: route.query.id,
      detail: props.data,
      showImg: true,
      showVideo: false,
      leaderTitle: 'Discussion Leaders',
      dialogVideo: false,
      showSurvey: false,
      surveylink: '',
      resourceList: [],
      showDialog: false,
      personDetail: {},
      meetingId:'mg9lr5rzd62to2hcidef11id',
      videoName:'',
      // translationpanelshow:true,
      pigeonholeIframeUrl:'',
      pigeonholepanelshow:false,
      embedVideoIframeUrl:'',
      embedyoutubeshow: true,
      embedvideoshow: false,
      embedlivestreamshow: false,
      embedvideoiframeshow:true,
      downloadedvideoshow:true,
      activeName: 'Translation',
      isallScreen: false,

    });
    data.originRateValue = props.data["rateValue"];
    const checkContentType = ()=>{
      let length = data.detail["bottomTargetList"].length;
      for(let i = 0; i < length; i++){
        let item = data.detail["bottomTargetList"][i];
        if(item.key=="Content Type:"){
          if(item.value=='pptx'||item.value=='ppt'){
            data.contentTypeIsppt = true;
          }else{
            data.contentTypeIsppt = false;
          }
        }
      }
    }
    // checkContentType();
    const carouselDataLength = computed(() => {
      return Math.ceil(data.detail["previewList"].length / 4)
    });
    // 查看当前是否加入了favorite
    // const checkFavorite = () => {
    //   let length = detail['assignedAttendees'].length;
    //   for (let i = 0; i < length; i++) {
    //     let item = detail['assignedAttendees'][i];
    //     let id = JSON.parse(localStorage.getItem('campusInfo')).userId;
    //     console.log("====item =====",item)
    //     console.log("====id =====",id)
    //     if (item == id) {
    //       data.showAddToFavorite = false;
    //       break;
    //     }
    //   }
    // };
    const checkFavorite = () => {
      let length = data.detail['assignedAttendees'].length;
      if (length == 0) {
        data.showAddToFavorite = true;
      } else {
        for (let i = 0; i < length; i++) {
          let item = data.detail['assignedAttendees'][i];
          // let id = JSON.parse(localStorage.getItem('campusInfo')).userId;
          let id = "";
          if(localStorage.getItem('campusInfo')){
            id = JSON.parse(localStorage.getItem('campusInfo')).userId;
          }
          if (item == id) {
            data.showAddToFavorite = false;
            break;
          } else {
            data.showAddToFavorite = true;
          }
        }
      }
    };
    // checkFavorite();
    // 打分
    const clickRate = () => {
      data.dialogVisible = true;
    };
    // 加入/取消 Favorite
    const toggleFavorite = () => {


      let d = {
        agendaId: data.detail.id,
        meetingId: 'a7yyxczuty0qpjo2393gmyad',
        isAdd: data.showAddToFavorite ? 1 : 0
      };
      if(d.isAdd == 1){
        elq.elqActivityForm(data.detail.id, "Add to Agenda", "");
      }

      if(data.showAddToFavorite){
        const loading = ElLoading.service({
          lock: true,
        })
        axios.request({
          method: 'POST',
          url: '/m/updateMyAgenda/',
          data: d
        }).then(res => {
          loading.close();
          ElMessageBox.alert('Add to your personal agenda.', 'Done and Done',{
            confirmButtonText: 'OK',
          }).then(()=>{
            data.showAddToFavorite = !data.showAddToFavorite;
          }).catch(()=>{
            data.showAddToFavorite = !data.showAddToFavorite;
          })
        }).catch(err => {
          console.log(err);
        });
        // ElMessageBox.confirm(
        //     'Add to your personal agenda.',
        //     'Done and Done',
        //     {
        //       confirmButtonText: 'OK',
        //       cancelButtonText: 'Cancel',
        //       type: 'warning',
        //     }
        // ).then(()=>{
        //   const loading = ElLoading.service({
        //     lock: true,
        //   })
        //   axios.request({
        //     method: 'POST',
        //     url: '/m/updateMyAgenda/',
        //     data: d
        //   }).then(res => {
        //     loading.close();
        //     console.log('res:', res);
        //     data.showAddToFavorite = !data.showAddToFavorite;
        //   }).catch(err => {
        //     console.log(err);
        //   });
        // })
      }else{
        ElMessageBox.confirm(
            'Removing this item will take it off your agenda.',
            'Think Twice',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'warning',
              closeOnClickModal:false
            }
        ).then(()=>{
          const loading = ElLoading.service({
            lock: true,
          })
          axios.request({
            method: 'POST',
            url: '/m/updateMyAgenda/',
            data: d
          }).then(res => {
            loading.close();
            console.log('res:', res);
            data.showAddToFavorite = !data.showAddToFavorite;
          }).catch(err => {
            console.log(err);
          });
        })
      }

      // axios.request({
      //   method: 'POST',
      //   url: '/m/updateMyAgenda/',
      //   data: d
      // }).then(res => {
      //   loading.close();
      //   console.log('res:', res);
      //   data.showAddToFavorite = !data.showAddToFavorite;
      // }).catch(err => {
      //   console.log(err);
      // });
    };
    //Open Survey
    const openSurvey = (d) => {
      console.log(d);
      elq.elqActivityForm(data.detail.id, "Click on Survey", '');
      console.log(d.surveylink);
      data.surveylink = d.surveylink;
      data.dialogSurvey = true;
    };

    //get Resource data
    const getResource = (id) => {
      console.log(data.detail.id,'111')
     const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/agendaResource',
        data: {
          meetingId: data.meetingId,
          agendaId: id,
        }
      }).then(res => {
        // console.log(res);
        if(res.data.result == 200){
          data.resourceList = res.data.contents;
          data.dialogResource = true;
        }
        loading.close();
      }).catch(err => {
        console.log(err);
      })
    }

    const clickView = (i) => {
      console.log(i);
      elq.elqActivityForm(data.detail.id, "View Resource", i.id);
      if(i.link != ''){
        if(i.link.indexOf("http") >= 0 ){
            window.open(i.link, '_blank');
        }else{
            window.open('/files/' + i.link, '_blank');
        }
      }
    }

    const onTimeupdate = (e) => {
      // console.log('1',e);
      // console.log(e.currentTime)
      // console.log(e.target.currentTime/e.target.duration)
      let percent = Math.ceil((e.target.currentTime/e.target.duration)*100);
      console.log(percent);
      if(percent > 80){
        let name = "video-complete-" + data.detail.id;
        let log = localStorage.getItem(name);
        if (log == undefined || log == ""){
            localStorage.setItem(name,'complete');
            // console.log(id, percent);
            elq.elqActivityForm(data.detail.id, "Watch Now - Complete", "");
        }
      }
    }

    const onPause = (e) => {
      // console.log(e)
    }

    const onDurationchange = (e) => {
      // console.log('2',e)
    }

    //timeElapsedHandler
    const timeElapsedHandler = ()=> {
      // console.log('guoqi')
      data.detail.showCoundown = false;
      data.showVideo = true;
      data.showImg = false;
    }
    //open Leader
    const openAttendee = (i) => {
      console.log(i)
     const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/attendees',
        data: {
          meetingId: data.meetingId,
          userId: i.id
        }
      }).then(res => {
        console.log(res)
        data.personDetail = res.data.data[0];
        data.showDialog = true;
        // person['files'] = res.data.data
        // data.showDialog = true;
        // context.emit("clickDetail", person);
        loading.close();
      }).catch(err => {
        loading.close();
        console.log(err);
      })
      // i.presenterStatus = true;

      // data.personDetail.presenterStatus = true;

    }
    // 确认打分
    const clickDone = () => {
     const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'POST',
        url: '/virtual/updateAgendaMark',
        data: {
          agendaId: data.id,
          rateValue: props.data["rateValue"],
        }
      }).then(res => {
        console.log('res:', res);
        data.originRateValue = props.data["rateValue"];
        loading.close();
        // ElMessage({
        //   type: 'success',
        //   message: 'success'
        // });
        data.dialogVisible = false;
      }).catch(err => {
        console.log(err);
        data.dialogVisible = false;
        loading.close();
      })
    };
    // const myVideo =  ref<HTMLElement | null>(null);
    // 点击Preview
    const clickPreview = () => {
      data.dialogPreview = true;
    };

    //close video
    const closeDialog = () =>{
      // data.detail.virtualLink='';
      // playVideo()
      console.log('111');
      // myVideo.video[0].pause();
      // stopVideo()
    };
    // 点击Preview弹窗里面的图片
    const clickCarouselImg = () => {

    };
    // 点击视频播放
    const clickVideo = () => {
      elq.elqActivityForm(data.detail.id, "Watch Now", "")
      elq.nextBeen(data.detail.id, "Watch Now", "")
      if(data.detail.videoName == 'Watch Now'){
        window['gtag']('event', 'Watch Now', {
          event_category: 'Watch Now',
          event_label: data.detail.name ,
          send_to: "UA-104780762-24",
        })
        // data.dialogVideo = true;
        // openVideo(data.detail.virtualLink,data.detail.id)
        // setTimeout(function(){
        //   playVideo();
        // },1000)
      }else if(data.detail.videoName == 'Watch On-Demand Now'){
        window['gtag']('event', 'Watch On-Demand Now', {
          event_category: 'Watch On-Demand Now',
          event_label: data.detail.name ,
          send_to: "UA-104780762-24",
        })
        // data.dialogVideo = true;
        // openVideo(data.detail.virtualLink,data.detail.id)
        // setTimeout(function(){
        //   playVideo();
        // },1000)

      }else{
        window['gtag']('event', 'Join Virtually', {
          event_category: 'Join Virtually',
          event_label: data. detail.name ,
          send_to: "UA-104780762-24",
        })
        // window.open(data.detail["virtualLink"], '_blank')
      }
      openVideo(data.detail.virtualLink,data.detail.id)
      // playVideo();
    }
    const playVideo = () => {

      let vid = data.detail.id;
      let video = <HTMLVideoElement>document.getElementById(vid);
      console.log(video);
      const videoEle = document.getElementsByTagName('video') // 获取页面上所有的video对象
      console.log(videoEle,'222')
      // const videoElement = this.videoElement
      if (videoEle && videoEle.length > 0) {
          for (let i = 0; i < videoEle.length; i++) {
            console.log(videoEle[i].id,'444')
            if(videoEle[i].id == vid){
              // videoEle[i].pause()
                  videoEle[i].play();
            }else{
              videoEle[i].pause()
            }
          }
      }

    }
    const stopVideo = () => {
      console.log('tttt')
      let vid = data.detail.id;
      let video = <HTMLVideoElement>document.getElementById(vid);
      video.pause();
    }

    const videoOptions = reactive({
      src: "", //视频源
      type: 'm3u8', //视频类型
      language: 'en',
    })
    // 视频判断类型
    const openVideo = (videoUrl,id) =>{
      let info = JSON.parse(localStorage.info);
      console.log(data.detail,8888)
      // videoUrl = 'https://cdn3.wowza.com/1/TnE2dmlkOWdPY2dn/Y0xKdXRw/hls/live/playlist.m3u8';
      // videoOptions.src = 'https://cdn3.wowza.com/1/TnE2dmlkOWdPY2dn/Y0xKdXRw/hls/live/playlist.m3u8';
      data.dialogVideo = true;
      console.log(videoUrl,99696);
      if( videoUrl.indexOf(".mp4") >= 0 ){
        let pigeonholeSessionId = data.detail.pigeonholeliveSessionId ? data.detail.pigeonholeliveSessionId : '';
        // var pigeonholeSessionId="3224479";
        if (pigeonholeSessionId != ""){
          // https://pigeonhole.at/4DXA4Y-fdfdfdfd/i/3224479?phEmail=melanie.li%40thecxapp.com&phName=Melanie%20Li
          // let pigeonholeUrl = "https://pigeonhole.at/ATM21";
           let pigeonholeUrl = "https://pigeonhole.at/4DXA4Y"
          pigeonholeUrl = pigeonholeUrl + "-" + info.userId;
          pigeonholeUrl = pigeonholeUrl + "/i/" + pigeonholeSessionId;
          pigeonholeUrl = pigeonholeUrl.indexOf("?") >= 0 ? pigeonholeUrl + "&phName=" : pigeonholeUrl + "?phName=";
          pigeonholeUrl = pigeonholeUrl + info.firstName;
          pigeonholeUrl = pigeonholeUrl + "&phEmail=" + info.email;
          pigeonholeUrl = pigeonholeUrl + "&disablebackbutton";
          data.pigeonholeIframeUrl = pigeonholeUrl;
          console.log('pigeonholeUrl', data.pigeonholeIframeUrl)
          data.pigeonholepanelshow = true;
          data.isallScreen = false;
        } else{
           data.pigeonholepanelshow = false;
          data.isallScreen = false;
        }
        setTimeout(function(){
          if (pigeonholeSessionId == "" || pigeonholeSessionId == undefined){
            data.pigeonholepanelshow = false;
              // $(".video-player-div").removeClass("col-md-7 col-lg-8 col-xl-9");
              // $(".video-player-div").addClass("col-md-12 col-lg-12 col-xl-12");
          }
          // data.embed = true
          // <HTMLElement>document.getElementsByClassName('pigeonhole-iframe powered-by')[0].style.display = "none";
          data.dialogVideo = true;
          data.embedyoutubeshow = false;
          data.embedlivestreamshow = false;
          data.embedvideoshow = true;
          data.downloadedvideoshow = false;
          data.embedvideoiframeshow = true;
          videoOptions.type = 'video/mp4';
          videoOptions.src = videoUrl;
          console.log('90909',videoOptions);
          // playVideo();
        }, 500);
      }else if (videoUrl.indexOf(".pdf") >= 0 || videoUrl.indexOf("zoom") >= 0){
        var win = window.open(videoUrl, '_blank');
        win.focus();
      }else{
        openLiveStream(videoUrl, id);
      }
    }

    const openLiveStream = (videoUrl, id) => {
      if(videoUrl.indexOf("youtube") >= 0){
          videoUrl = videoUrl + "&enablejsapi=1";
          data.embedVideoIframeUrl = videoUrl;
          data.embedyoutubeshow = false;
          data.embedlivestreamshow = true;
          data.embedvideoshow = true;
          // data.translationpanelshow = true;
          data.pigeonholepanelshow = false;
      }else{
          data.embedyoutubeshow = true;
          data.embedlivestreamshow = false;
          // data.translationpanelshow = false;
          data.pigeonholepanelshow = true;
          // $("#hls-player source").attr("src", videoUrl);
      }
      let info = JSON.parse(localStorage.info);
      // console.log(info,8888);
      var pigeonholeSessionId = data.detail.pigeonholeliveSessionId ? data.detail.pigeonholeliveSessionId : '';
      // var pigeonholeSessionId="3224479";
      if (pigeonholeSessionId != ""){
          // var pigeonholeUrl = "https://pigeonhole.at/ATM21"
          let pigeonholeUrl = "https://pigeonhole.at/4DXA4Y"
          pigeonholeUrl = pigeonholeUrl + "-" + info.userId;
          pigeonholeUrl = pigeonholeUrl + "/i/" + pigeonholeSessionId;
          pigeonholeUrl = pigeonholeUrl.indexOf("?") >= 0 ? pigeonholeUrl + "&phName=" : pigeonholeUrl + "?phName=";
          pigeonholeUrl = pigeonholeUrl + info.firstName + ' ' + info.lastName;
          pigeonholeUrl = pigeonholeUrl + "&phEmail=" + info.email;
          pigeonholeUrl = pigeonholeUrl + "&disablebackbutton";
          data.pigeonholeIframeUrl = pigeonholeUrl;
          console.log('pigeonholeUrl', data.pigeonholeIframeUrl)
          // data.isallScreen = false;
           data.pigeonholepanelshow = true;
        } else{
           data.pigeonholepanelshow = false;
          // data.isallScreen = true;
        }
      // data.translationpanelshow = false;

      setTimeout(function(){
          // $(".pigeonhole-iframe .powered-by").css("display", "none");
          // $("#liveStreamModal").modal('show');
          if(videoUrl.indexOf("youtube") < 0){
            videoOptions.type = 'm3u8';
            // videoOptions.src = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
            videoOptions.src = videoUrl;
            console.log(videoUrl,888888);
            // videoOptions.src = data.pigeonholeIframeUrl;
              // var player = videojs('hls-player');
              // player.play();
              // video_s.value = res.data.mydata;
              // playerOptions.value.sources[0].src=data.pigeonholeIframeUrl ;//这里对url赋值
              // console.log("video", playerOptions);
          }else{
              // new YT.Player('embed-player');
          }

      }, 500);

    }

    const videoEnd = () => {
      console.log('is end');
      // elq.elqActivityForm(data.detail.id, "Watch Now - Complete", "");
    }


    // 获取当前content的类型，根据类型展示不同界面
    const getContentType = () => {
      console.log("====data =====",data)
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
      // let convertTimeStart = moment.utc(data.detail.start).local().format('YYYY-MM-DD HH:mm:ss');
      // let convertTimeEnd = moment.utc(data.detail.end).local().format('YYYY-MM-DD HH:mm:ss');
      let st = moment(data.detail.start).format('YYYY-MM-DD HH:mm:ss');
      let et = moment(data.detail.end).format('YYYY-MM-DD HH:mm:ss');
      let convertTimeStart = moment(data.detail.start).format('YYYY-MM-DD HH:mm:ss');
      let convertTimeEnd = moment(data.detail.end).format('YYYY-MM-DD HH:mm:ss');
      if(data.detail.watchNowTime != ''){
        convertTimeStart = moment(data.detail.watchNowTime).format('YYYY-MM-DD HH:mm:ss');
        st = data.detail.watchNowTime;
      }else{
        convertTimeStart = moment(data.detail.start).format('YYYY-MM-DD HH:mm:ss');
      }
      
      // console.log(moment().format('YYYY-MM-DD HH:mm:ss'),'2222',data.detail.start)
      // data.detail.contributors.data = data.detail.contributors
      // data.detail.countdownTime = moment(convertTimeStart).subtract(5,'minutes').format("YYYY-MM-DD HH:mm:ss")
      data.detail.countdownTime = moment(convertTimeStart).format("YYYY-MM-DD HH:mm:ss")
      if(data.detail.onDemand){
        data.detail.countdownTimeText = moment(data.detail.start).format("MMMM DD") +' from '+ moment(data.detail.start).format("hh:mm a") + ' and On-Demand After ';
      }else{
        data.detail.countdownTimeText = moment(data.detail.start).format("MMMM DD") +' from '+ moment(data.detail.start).format("hh:mm a") + ' to ' + moment(convertTimeEnd).format("hh:mm a");
      }
      

      // if(data.detail.timeBracketing){
      //   data.detail.isShowcountdownTimeText = true;
      //     if(data.detail.virtualLink){
      //       if(data.detail.showWatchNow){
      //         if(data.detail.onDemand){
      //           data.detail.countdownTimeText = data.detail.countdownTimeText + 'and On-Demand After';
      //           if(data.detail.virtualLink.indexOf('.mp4') > -1){
      //             data.videoName = 'Watch On-Demand Now';
      //           }else{
      //             data.videoName = 'Join Virtually';
      //           }
      //         }else{
      //           if(data.detail.virtualLink.indexOf('.mp4') > -1){
      //             data.videoName = 'Watch Now';
      //           }else{
      //             data.videoName = 'Join Virtually';
      //           }
      //         }
      //       }
      //     }
      // }else{

      // }

      if(data.detail.virtualLink != ''){
        if(data.detail.onDemand == true){
          // data.detail.isShowcountdownTimeText = false;
          // console.log('shide')
          if(data.detail.virtualLink.indexOf('.mp4') > -1 || data.detail.virtualLink.indexOf('m3u8') > -1){
            data.detail.videoName = 'Watch On-Demand Now';
          }else{
            data.detail.videoName = 'Join Virtually';
          }

        }else{
          // data.detail.isShowcountdownTimeText = true;
          if(data.detail.virtualLink.indexOf('.mp4') > -1 || data.detail.virtualLink.indexOf('m3u8') > -1){
            data.detail.videoName = 'Watch Now';
          }else{
            data.detail.videoName = 'Join Virtually';
          }
        }
      }
              // {
        //   'title': 'Yahoo',
        //   'link': 'http://calendar.yahoo.com/?v=60&title='+ encodeURIComponent(data.detail.name) + '&st'+
        //    moment(data.detail.start.split(" ")[0]).format("YYYYMMDD")+'T'+data.detail.start.split(" ")[1] +
        //    '&et'+ moment(data.detail.end.split(" ")[0]).format("YYYYMMDD")+'T'+data.detail.end.split(" ")[1] +'=&dur=&in_loc=Internet&desc='+ 
        //    encodeURIComponent(data.detail.description + ' Click here: <a href="https://arubaatmosphere2022.com/#/content?id="'+data.detail.id+'>https://arubaatmosphere2022.com/#/content?id='+data.detail.id+'</a>')
        // },
      // console.log('0000',moment(data.detail.start).format('YYYYMMDDTHHmmss'));
      // console.log('111',moment(data.detail.end.split(" ")[0]).format("YYYYMMDD")+'T'+moment(data.detail.end.split(" ")[1]).format('HHmmss'))
      let ost = moment(data.detail.start).format('YYYY-MM-DDTHH:mm:ss');
      let oed = moment(data.detail.end).format('YYYY-MM-DDTHH:mm:ss');
      data.detail.calendarList = [
        {
          'title': 'Google',
          'link': 'https://www.google.com/calendar/render?action=TEMPLATE&text='+ encodeURIComponent(data.detail.name) + '&dates='+ moment(data.detail.start.split(" ")[0]).format("YYYYMMDD")+'T'+data.detail.start.split(" ")[1] + '/' + moment(data.detail.end.split(" ")[0]).format("YYYYMMDD")+'T'+data.detail.end.split(" ")[1] +'&details='+ encodeURIComponent(data.detail.description + ' Click here: <a href="https://arubaatmosphere2022.com/#/content?id="'+data.detail.id+'>https://arubaatmosphere2022.com/#/content?id='+data.detail.id+'</a>')
        },
        {
          'title': 'O365',
          'link': 'https://outlook.office365.com/owa/?path=/calendar/action/compose&'+
          'rru=addevent&subject='+ encodeURIComponent(data.detail.name) + '&startdt='+ost+
          '&enddt='+oed+'&body='+encodeURIComponent(data.detail.description + ' Click here: <a href="https://arubaatmosphere2022.com/#/content?id="'+data.detail.id+'>https://arubaatmosphere2022.com/#/content?id='+data.detail.id+'</a>')+'&location='
        }
      ];
      data.detail.isShowcountdownTimeText = true;
      // console.log('sunsun', data.detail.countdownTime);
      // console.log(moment().format('YYYY-MM-DD HH:mm:ss'));
      // console.log(data.detail.name,data.detail["virtualLink"],data.detail.countdownTime);
      // console.log('jiancha',moment().valueOf(),data.detail.onDemand, moment(data.detail.end).valueOf(),moment().valueOf() < moment(data.detail.end).valueOf());
      // console.log(data.detail.countdownTime,'test', moment.utc(moment().format('YYYY-MM-DD HH:mm:ss')).local().format('YYYY-MM-DD HH:mm:ss'),moment(data.detail.countdownTime).valueOf(),moment.utc(moment().format('YYYY-MM-DD HH:mm:ss')).local().valueOf(),moment.utc(moment().format('YYYY-MM-DD HH:mm:ss')).local().valueOf() < moment(data.detail.countdownTime).valueOf())
      if(moment().valueOf() < moment(data.detail.countdownTime).valueOf()){
        data.detail.showCoundown = true;
        data.showVideo = false;
        data.showImg = true;
      }else{
        data.detail.showCoundown = false;
        if (data.detail["virtualLink"]!="") {
          if(data.detail.onDemand == true){
            data.showVideo = true;
            data.showImg = false;
          }else{
            // console.log('1243',moment().valueOf(),data.detail.start,moment.utc(data.detail.start).local().format('YYYY-MM-DD HH:mm:ss'),moment().valueOf() < moment.utc(data.detail.end).local().valueOf(),moment().valueOf() > moment.utc(data.detail.start).local().valueOf())
            if(moment().valueOf() < moment(data.detail.end).valueOf() && moment().valueOf() > moment(st).valueOf()){
              data.showVideo = true;
              data.showImg = false;
            }else{
              data.showVideo = false;
              data.showImg = true;
            }
          }
        } else {
          data.showVideo = false;
          data.showImg = true;
        }
      }
      console.log("====detail =====",data.detail)
      // console.log("==== data.detail.contentType=====",props.data["ContentType"])
      // if (data.detail["ContentType"] == 'video'&&data.detail["virtualLink"]!="") {
      //   data.showVideo = true;
      //   data.showImg = false;
      // } else {
      //   data.showVideo = false;
      //   data.showImg = true;
      // }
    }

    getContentType();

    const goLink = (v) => {
      console.log(v);
      elq.elqActivityForm(data.detail.id, "Add to Calendar", "");
      window.open(v.link, '_blank');
    }

    const downLoad = () => {
      // window.open('vmWare/fileDownload?id'+data.id);
      axios.request({
        method: 'GET',
        url: '/virtual/fileDownload',
        params: {
          id: data.id
        }
      }).then(res => {
        window.open(res.data.data.fileUrl);
      }).catch(err => {
        console.log(err);
      })
    };
    const handlePlay = (id) => {
      const videoEle = document.getElementsByTagName('video') // 获取页面上所有的video对象
      console.log(videoEle,'222')
      // const videoElement = this.videoElement
      if (videoEle && videoEle.length > 0) {
          for (let i = 0; i < videoEle.length; i++) {
            console.log(videoEle[i].id,'444')
            if(videoEle[i].id == id){
              // videoEle[i].pause()
            }else{
              videoEle[i].pause()
            }
          }
      }
    };

    const toggleBriefcase = (id) => {
      // console.log("====lists =====",data.lists)
      console.log("==== id=====", id)
      let length = data.resourceList.length;
      for (let i = 0; i < length; i++) {
        let item = data.resourceList[i];
        if (item.id == id) {
          // item.isBriefcase = !item.isBriefcase;
          let arr = [];
          arr.push(id);
          if (item.isBriefcase) {
            elq.elqActivityForm(data.detail.id, "Add to Briefcase", id);
            const loading = ElLoading.service({
              lock: true,
            })
            axios.request({
              method: 'POST',
              url: '/virtual/briefcase',
              data: {
                contentId: arr
              }
            }).then(res => {
              console.log('res:', res);
              ElMessageBox.confirm(
                  'Item added to your Virtual Briefcase.',
                  'Done and Done',
                  {
                    confirmButtonText: 'GO There Now',
                    cancelButtonText: 'OK',
                    type: 'info',
                    closeOnClickModal:false
                  }
              ).then(res=>{
                router.push({name:'VirtualBriefcase'})
                item.isBriefcase = !item.isBriefcase;
                loading.close();
              }).catch(()=>{
                item.isBriefcase = !item.isBriefcase;
                loading.close();
              })

            }).catch(err => {
              console.log(err);
              loading.close();
            })
          } else {
            ElMessageBox.confirm(
                'Are you sure you want to remove this item? You may add them again later from their respective events/meetings.',
                'Think Twice',
                {
                  confirmButtonText: 'Remove',
                  cancelButtonText: 'Cancel',
                  type: 'warning',
                  closeOnClickModal:false
                }
            ).then(()=>{
              const loading = ElLoading.service({
                lock: true,
              })
              axios.request({
                method: 'DELETE',
                url: '/virtual/briefcase',
                data: {
                  contentId: arr
                }
              }).then(res => {
                console.log('res:', res);
                item.isBriefcase = !item.isBriefcase;
                loading.close();
              }).catch(err => {
                console.log(err);
                loading.close();
              })
            })

          }
          break
        }
      }
    }
    const openTab = (url)=>{
      if(url){
        window.open(url)
      }
    }
    watch(() => props.data, (newValue, oldVlaue) => {

      data.showImg = false;
      data.showPerson = false;
      data.detail = newValue;
      console.log(data.detail)
      getContentType();
      nextTick(() => {
        data.showImg = true;
        data.showPerson = true;
      });
      checkFavorite();
    });
    watch(() => data.dialogVideo, (newValue, oldVlaue) => {
      if (!newValue) {
        // stopVideo();
      }
    });
    watch(() => data.dialogVisible, (newValue, oldVlaue) => {
      if (!newValue) {
        console.log("====originRateValue =====", data["originRateValue"])
        data.detail["rateValue"] = data["originRateValue"];
      }
    });
    onMounted(() => {

    });
    return {
      ...toRefs(data),
      router,
      carouselDataLength,//4张图片为一组，将轮播图分为几组
      clickRate,
      clickDone,
      checkFavorite,
      // detail,
      toggleFavorite,
      clickPreview,
      clickCarouselImg,
      clickVideo,
      playVideo,
      stopVideo,
      getContentType,
      downLoad,
      checkContentType,
      openSurvey,
      timeElapsedHandler,
      getResource,
      clickView,
      openAttendee,
      closeDialog,
      myVideo,
      handlePlay,
      // action icon
      imgSurvey,
      imgSurveyActive,
      imgAgenda,
      imgAgendaActive,
      imgResources,
      imgResourcesActive,
      imgCalendar,
      imgCalendarActive,
      imgConversation,
      imgConversationActive,
      client,
      openVideo,
      openTab,
      openLiveStream,
      videoOptions,
      goLink,
      isFull,
      videoEnd,
      toggleBriefcase,
      onTimeupdate,
      onDurationchange,
      onPause
    };
  },
});
