<template>
  <div class="contributor" v-show="person.presenterStatus">
    <div class="contributorTop">
      <el-avatar v-if="person.img||person.image" :size="130" :src="person.img||person.image"></el-avatar>
      <el-avatar v-else :size="130" style="font-size: 30px">
        {{ person.firstName[0] }}&nbsp;{{ person.lastName[0] }}
      </el-avatar>
    </div>
    <div class="contributorBottom">
      <p class="name" v-if="person.name">{{ person.name }}</p>
      <p class="name" v-else>{{ person.firstName }}&nbsp;{{ person.lastName }}</p>
      <div class="info">
        <p class="title">{{ person.title }}</p>
        <p class="des">{{ person.company }}</p>
      </div>
      <el-button class="x-btn" v-if="!showEmail" @click="clickDetail">More Detail</el-button>
      <!--<el-button class="x-btn" v-else @click="clickEmail">Email</el-button>-->
      <a v-else :href="'mailto:'+person.email" class="email" target="_blank" rel="noopener noreferrer">Email</a>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs, computed, onBeforeMount, onMounted} from "vue";
import axios from '@/api/http.js'
import {ElLoading} from 'element-plus';

export default {
  name: "",
  props: {
    person: {
      type: Object,
    },
    showEmail: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const data = reactive({
      list: [{name: "123"}],
    });
    // console.log("person:", props.person);
    const person = props.person;
    const name = computed(() => {
      return person.firstName[0] + person.lastName[0];
    });
    const clickDetail = () => {
      console.log("==== person!!!!=====", person)
      window['gtag']('event', 'Attendee Details click', {
        event_category: 'Main Attendee Tab More Details',
        event_label: person.firstName + ' ' + person.lastName,
        send_to: "UA-104780762-24",
      })
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/presenter',
        data: {
          userId: person.id
        }
      }).then(res => {
        person['files'] = res.data.data
        context.emit("clickDetail", person);
        loading.close();
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    const clickEmail = () => {
      context.emit("showEmail", person);
    }
    onBeforeMount(() => {
    });
    onMounted(() => {
    });
    return {
      ...toRefs(data),
      person,
      name,
      clickDetail,
      clickEmail
    };
  },
};
</script>
<style scoped lang='scss'>
.contributor {
  width: 350px;

  .contributorTop {
    background: #0191da;
    //height: 200px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contributorBottom {
    background: #fff;
    //height: 200px;
    height: 300px;
    display: flex;
    flex-flow: column;
    align-items: center;

    .name {
      color: #000;
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
    }

    .info {
      //height: 70px;
      height: 170px;

      .title {
        color: gray;
        font-size: 15px;
        margin: 10px 0;
        text-align: center;
        word-break: break-word;
      }

      .des {
        color: #1d4290;
        font-size: 15px;
        margin: 10px 0;
        text-align: center;
      }

    }

    .email {
      background: #FE8300;
      width: 60px;
      text-align: center;
      padding: 10px 30px;
      color: #fff;
      text-decoration: none;
      font-family: OpenSans-Semibold;
    }

    button {
      margin: 10px 0;
    }
  }

  //.contributorDetail {
  //  .containerLeft {
  //    .contributor {
  //      height: 100%!important;
  //      .contributorBottom {
  //        .info {
  //          margin-bottom: 30px!important;
  //        }
  //      }
  //    }
  //  }
  //}
  .contributorsContainer {
    .info {
      margin-bottom: 30px !important;
    }
  }
}

</style>
<style lang="scss">
.contributor {
  .el-avatar > img {
    width: 100%;
  }
}
</style>
