
import {defineComponent, computed, reactive, toRefs, onBeforeMount, onMounted} from 'vue';
import contributor from '@/components/page/contributor.vue';


export default defineComponent({
  name: '',
  props: {
    data: {
      type: Object,
    },
    ContributorText: {
      type: String,
      default: 'Attendees'
    }
  },
  components: {
    contributor
  },
  setup(props) {
    window.addEventListener("resize", function () {
      let width = window.innerWidth;
      if (width < 992) {
        data.dialogWidth = '90%';
      } else {
        data.dialogWidth = 900;
      }
    })
    const name = computed(() => {
      return props.data.firstName[0] + props.data.lastName[0];
    });
    const data = reactive({
      dialogWidth: null,
    });
    return {
      name,
      ...toRefs(data)
    };
  }
});
